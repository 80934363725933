
import {Notification} from "rsuite";

export const SetMessage= (message,type)=>{
    Notification[type]({
        title: type,
        description: message
    });
}

