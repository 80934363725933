/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import * as xlsx from "xlsx-js-style";
import Sheet from "./Sheet";
import { stambData } from "./data";
import { exportData } from "./xlsxExporter";
import { formatToCurrency } from "./utils";
import { useLocation } from "react-router-dom";
import Button from "../../components/Button/Button";

import axios from "axios";
const SheetPage = () => {
  const { url, name } = useLocation();
  console.log("url", url);

  const [data, setdata] = useState(stambData);

  const testAxiosXlsx = async (url) => {
    const options = {
      url,
      responseType: "arraybuffer",
    };
    let axiosResponse = await axios(options);
    const workbook = xlsx.read(axiosResponse.data);

    console.log("workbook", workbook);
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    const json = xlsx.utils.sheet_to_json(worksheet);

    const hasValue = json
      .filter((j) => j && j["Transaction Analysis Sheet"] && j.__EMPTY)
      .map((j) => [j["Transaction Analysis Sheet"], j["__EMPTY"], ""]);

    return hasValue;
  };

  const handleWithCurrency = (data) => {
    return data.map((d) => {
      if (d) {
        if (d) {
          d[1] = "$" + formatToCurrency(d[1]);
          return d;
        }
      }
    });
  };
  useEffect(() => {
    const responseData = testAxiosXlsx(url);
    responseData.then((data) => {
      console.log("data Mapped ", data);
      const currencyFormatted = handleWithCurrency(data);
      setdata(currencyFormatted);
    });
    // const currencyFormatted = handleWithCurrency(stambData);
    // setdata(currencyFormatted);

    // const currencyFormatted = hasValue.map((d) => {
    //   if (d) {
    //     d[1] = formatToCurrency(d[1]);
    //     return d;
    //   }
    // });
    // setdata(currencyFormatted);
  }, []);

  return (
    <section className="py-5">
      {data.length ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {data && <Sheet data={data} name={name} />}

          <Button
            title="download"
            onClick={() => exportData(data, name)}
          ></Button>

          <></>

          <></>
        </div>
      ) : (
        <div>No data to show</div>
      )}
    </section>
  );
};

export default SheetPage;

// sort results
/*

https://drive.google.com/drive/u/0/folders/1cHOQG_BuVXEZ9meRSEfKb7Z_I84idNTw


input file ==> https://docs.google.com/spreadsheets/d/1QBgBt8PvZXA-l0jYdFpV7fYRPGgW0Tjt/edit#gid=1525742837
output file ==> https://docs.google.com/spreadsheets/d/1jK_qbDnDeqg5Nf7RzXryqzW9hqguyU1w/edit#gid=2011536465
*/
