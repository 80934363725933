import {createSlice} from '@reduxjs/toolkit';
const auth = createSlice({
  name: 'auth',
  initialState: {
    loggedInUser: {
      name: null,
      email: null,
      credit: null,
    },
    loading: false,
    error:false
  },
  reducers: {
    setLoggedInSuccess(state, action) {
      state.loggedInUser.name = action.payload.name;
      state.loggedInUser.email = action.payload.email;
      state.loggedInUser.credit = action.payload.credit
      state.loading = false;
    },
    signIn(state, action) {
      state.loading = true;
    },
    setLoggedInFailed(state, action) {
      state.loading = false;
      state.error = false
    },
    signOut(state, action){
      state.loggedInUser ={
          name: false,
          email: false,
          credit: false,
    }
    },
    changeCredit(state,action){
      state.loggedInUser.credit = state.loggedInUser.credit + action.payload
    },
    setCredit(state,action){
      state.loggedInUser.credit =  action.payload
    },
  },
});

const {actions, reducer} = auth;

export const {setLoggedInSuccess, setLoggedInFailed, signIn,signOut,changeCredit,setCredit} = actions;

export default reducer;


