import React, {useEffect, useState} from "react";
import Button from "../../components/Button/Button";

import axios from 'axios';


const AddFunds = ()=>{

    const [amount,setAmount] = useState(39.99)
    const [loading , setLoading ]= useState(false)
    const onQuantityChange= (event)=>{
        setAmount((parseInt(event.target.value) *39.99).toFixed(2))
    }
    const checkout = ()=>{
        setLoading(true)
        axios.post('/pay',{
            amount
        }).then(res=>{
            window.open(res.data.paymentIntentSecret)
            setLoading(false)
        }).catch(err=>{
            setLoading(false)
        })
    }
    return(
        <div style={{display:"flex",flexDirection:'column',flex:1}}>
            <section className="container">
                <h1>Add Funds</h1>
            </section>
            <section className="container" style={{flex:1,flexDirection:"column"}}>
                <ul style={{borderTop:'1px solid #ddd', padding:0,margin:0,listStyleType:'none'}}>
                    <li className="row" style={{ justifyContent:'space-between', alignItems:'center',display:'flex',flexDirection:'row', marginTop:'3rem', marginBottom:'3rem'}}>
                        <div style={{flex:0.5,flexDirection:'column',alignItems:'center'}}>
                            <div style={{fontSize:'1.2rem'}}> BookReadyPro Credit </div>
                            <div style={{fontSize:'1rem', color:'#7d7d7d', marginTop:'10px'}}>1 Credit can process 1 document </div>
                            <div style={{fontSize:'1.5rem',marginTop:'10px'}}>{`$${amount}`}</div>
                        </div>
                        <div style={{flex:0.3, flexDirection:'row', justifyContent:'center', alignItems:'center' }}>
                             <input type="number" style={{width:'60px', height:'60px', background:'#fff',paddingLeft:"14px",
                                border:'2px solid #ddd', color:'#7f7f7f',textAlign:'center'}} defaultValue={1} onChange={e =>  onQuantityChange(e)} step={1} min={1} 
                                onKeyDown={e => {
                                    // Get the current value of the input field
                                    const currentValue = e.target.value.trim();
                         
                                    // Check if the key pressed is a number between 1 and 9, the backspace key (key code 8), or any other valid key
                                    const isNumberKey = (e.keyCode >= 49 && e.keyCode <= 57) || e.keyCode === 8;
                         
                                    // Check if the current value is already greater than zero
                                    const isValueGreaterThanZero = currentValue > 0;
                         
                                    // If the key pressed is a valid number or the backspace key and the current value is not greater than zero,
                                    // allow the default behavior of the key press event
                                    if (isNumberKey && !isValueGreaterThanZero) {
                                        return;
                                    }
                         
                                    // If the key pressed is not a valid number or the current value is already greater than zero,
                                    // prevent the default behavior of the key press event
                                    if (!isNumberKey || !isValueGreaterThanZero) {
                                        e.preventDefault();
                                    }
                                }}
                                onBlur={e => {
                                    // Get the current value of the input field
                                    const currentValue = e.target.value.trim();
                         
                                    // If the current value is empty or NaN, set the value to 1
                                    if (currentValue === "" || isNaN(currentValue)) {
                                        e.target.value = 1;
                                        onQuantityChange(e);
                                    }
                                }}  
    
                                />
                        </div>
                    </li>
                </ul>
                <div style={{borderBottom:'1px solid #ddd', flex:1,justifyContent:'space-between'}}/>
                <div style={{display:"flex"}}>
                    <div style={{flex:0.7}}/>
                    <div style={{flex:0.2,flexDirection:'column'}}>
                        <p style={{fontWeight:'bold', padding:'1rem 0 1rem 0', fontSize:'1.5rem'}}> Total <p style={{marginLeft:'20px',fontWeight:'bold',display:"inline"}}> {`$${amount}`}</p> </p>
                        <Button  title={"Checkout"} onClick={checkout}  loading={loading}/>
                    </div>
                </div>
            </section>
        </div>

    )

}
export default AddFunds
