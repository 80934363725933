import { Icon, Table, Whisper, Tooltip, Notification } from "rsuite";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const { Column, HeaderCell, Cell } = Table;
const tooltip = (
  <Tooltip>
    Eror happened while processing you have been refunded the credits
  </Tooltip>
);
const TreeTable = ({ data, getFiles }) => {
  const [expandedRow, setExapndedRow] = useState(["0"]);
  useEffect(() => {
    console.log("DATA",data)
    if (data[0] && data[0].children) {
      let temp = [...new Set([data[0].id, ...expandedRow])];
      setExapndedRow(temp);
    }
  }, [data]);
  const deleteFile = (parentFileId, outputFileId) => {
    Notification["info"]({
      title: "File is being deleted",
      description: "The File is being Deleted",
    });
    axios
      .delete("/files", {
        data: {
          parentFileId,
          outputFileId,
        },
      })
      .then(() => {
        getFiles();
        Notification["success"]({
          title: "File Deleted",
          description: "File was successfully Deleted",
        });
      });
  };

  const trigger = (id) => {
    let temp = expandedRow;
    if (temp.includes(id)) temp = temp.filter((exapndedId) => exapndedId != id);
    else temp.push(id);

    setExapndedRow(temp);
  };

  return (
    <div>
      <Table
        isTree
        rowKey="id"
        height={700}
        data={data}
        expandedRowKeys={expandedRow}
        onRowClick={(rowData) => {
          trigger(rowData.id);
        }}
        renderTreeToggle={(icon, rowData) => {
          if (rowData.children && rowData.children.length === 0) {
            if (rowData.error) return null;
            else return <Icon icon="spinner" spin />;
          }
          return icon;
        }}
      >
        <Column flexGrow={2}>
          <HeaderCell>Label</HeaderCell>
          <Cell dataKey="name" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Status</HeaderCell>
          <Cell>
            {(rowData) => {
              if (rowData.children && rowData.children.length === 0) {
                if (rowData.error)
                  return (
                    <Whisper placement="top" trigger="hover" speaker={tooltip}>
                      <span>
                        <Icon icon="warning" />
                        <a style={{ color: "inherit", textDecoration: "none" }}>
                          {" "}
                          Error
                        </a>
                      </span>
                    </Whisper>
                  );
                else
                  return (
                    <span>
                      <Icon icon="spinner" spin />{" "}
                      <a style={{ color: "inherit", textDecoration: "none" }}>
                        {" "}
                        Processing
                      </a>
                    </span>
                  );
              } else
                return (
                  <a style={{ color: "inherit", textDecoration: "none" }}>
                    {" "}
                    Completed
                  </a>
                );
            }}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Uploaded At</HeaderCell>
          <Cell>
            {(rowData) => {
              return (
                <span>
                  <a style={{ "text-decoration": "none", color: "grey" }}>
                    {" "}
                    {new Date(rowData.createdAt).toDateString()}{" "}
                  </a>
                </span>
              );
            }}
          </Cell>
        </Column>

        <Column flexGrow={2} fixed="right">
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {(rowData) => {
              let outputFileId = "";
              let parentFileId = "";


              // console.log("rowData",rowData)
              if (rowData.parentFileId) outputFileId = rowData.id;
              else parentFileId = rowData.id;
              return (
                <span>
                  {outputFileId && (
                    <>
                      <a
                        href={`${process.env.REACT_APP_BACKEND_API}/download/file?outputFileId=${outputFileId}&parentFileId=${parentFileId}`}
                      >
                        Download{" "}
                      </a>

                      {rowData.name.includes("Transactio") ? (
                        <Link
                          to={{
                            pathname: "/sheet",
                            name:rowData.name,
                            url: `${process.env.REACT_APP_BACKEND_API}/download/file?outputFileId=${outputFileId}&parentFileId=${parentFileId}`,
                          }}
                        >
                          Edit
                        </Link>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  {parentFileId && (
                    <>
                      <a
                        href={`${process.env.REACT_APP_BACKEND_API}/download/file?outputFileId=${outputFileId}&parentFileId=${parentFileId}`}
                      >
                        Download{" "}
                      </a>
                    </>
                  )}

                  {parentFileId && (
                    <a
                      href="#"
                      onClick={(e) => deleteFile(parentFileId, outputFileId)}
                    >
                      | Delete
                    </a>
                  )}
                </span>
              );
            }}
          </Cell>
        </Column>
      </Table>
    </div>
  );
};

export default TreeTable;
