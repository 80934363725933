import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import { Notification } from 'rsuite';
import {clearError} from "../../store/error";

const Error =({error})=>{

    useEffect(()=>{
        if(error) {
            Notification['error']({
                title: 'error',
                description: error
            });
        }
    },[error])

    return null

}
export default connect(({error}) => {
    return {
        error: error.error,
    };
}, {clearError})(Error);