const formatToCurrency = (amount) => {
  const number = Math.abs(Number(amount))
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return amount >= 0 ? number : `(${number})`;
};

const parseCurrency = (amount) => {
  const number = Number(amount.replace(/[^0-9.-]+/g, ""));
  return number;
};

export { formatToCurrency, parseCurrency };
