import React from "react";
import NativeListener from "react-native-listener";
import { BaseEditorComponent } from "@handsontable/react";
import { getSortedCategories, setCategory, category } from "./data";

export default class EditorComponent extends BaseEditorComponent {
  constructor(props) {
    super(props);

    this.mainElementRef = React.createRef();
    this.buttonStyle = {
      margin: "6px 0",
      padding: "4px 10px",
      width: "100%",
    };
    this.containerStyle = {
      display: "none",
      position: "fixed",
      right:0,
      top: 0,
      border: "1px solid #aaa",
      padding: "0 15px",
      zIndex: 999,
      maxHeight: "500px",
      overflow: "scroll",
      width: "250px",
      transform: "translate(0, -50%)",
      background: "#fff",
      paddingTop: "10px",
    };
    this.state = {
      allList: getSortedCategories(),
      value: "",
      searchValue: "",
      searchList: getSortedCategories(),
    };
  }

  setValue(value, callback) {
    this.setState((state, props) => {
      return { value: value };
    }, callback);
  }

  getValue() {
    return this.state.value;
  }

  open() {
    this.mainElementRef.current.style.display = "block";
  }

  close() {
    this.mainElementRef.current.style.display = "none";
  }

  prepare(row, col, prop, td, originalValue, cellProperties) {
    // We'll need to call the `prepare` method from
    // the `BaseEditorComponent` class, as it provides
    // the component with the information needed to use the editor
    // (hotInstance, row, col, prop, TD, originalValue, cellProperties)
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    const tdPosition = td.getBoundingClientRect();

    // As the `prepare` method is triggered after selecting
    // any cell, we're updating the styles for the editor element,
    // so it shows up in the correct position.
    this.mainElementRef.current.style.left = tdPosition.left + "px";
    this.mainElementRef.current.style.top = "50%";
  }

  setLowerCase(aaaa, b) {
    this.setState(
      (state, props) => {
        return { value: aaaa, searchValue: "", searchList: this.state.allList };
      },
      () => {
        this.finishEditing();
      }
    );
  }

  setUpperCase() {
    this.setState(
      (state, props) => {
        return { value: this.state.value.toString().toUpperCase() };
      },
      () => {
        this.finishEditing();
      }
    );
  }

  stopMousedownPropagation(e) {
    e.stopPropagation();
  }

  handleSearch(value) {
    this.setState({ searchValue: value });
    const searchList = this.state.allList.filter((c) => {
      console.log(c.includes);
      return c.toUpperCase().includes(value.toUpperCase());
    });

    this.setState({ searchList: searchList });

    console.log("search list", searchList);
  }
  addCategory() {
    const newList = [...this.state.allList, this.state.searchValue];
    this.setState({
      allList: newList,
    });
    setCategory(newList);
    this.setLowerCase(this.state.searchValue);
  }

  render() {
    return (
      <NativeListener onMouseDown={this.stopMousedownPropagation}>
        <div
          style={this.containerStyle}
          ref={this.mainElementRef}
          id="editorElement"
        >
          <form>
            <label htmlFor="cat">
              <input
                style={{
                  width: "calc(100% - 30px)",
                  position: "fixed",
                  marginBottom: "10px",
                }}
                type="text"
                id="cat"
                name="hi"
                value={this.state.searchValue}
                placeholder="Quick search"
                onChange={(e) => this.handleSearch(e.target.value)}
              />
            </label>
          </form>
          <div style={{ marginTop: "35px" }}>
            {this.state.searchList.length ? (
              <>
                {this.state.searchList.map((b) => (
                  <button
                    style={this.buttonStyle}
                    onClick={this.setLowerCase.bind(this, b)}
                  >
                    {b}
                  </button>
                ))}
              </>
            ) : (
              <button
                style={this.buttonStyle}
                onClick={(e) => this.addCategory()}
              >
                Add category
              </button>
            )}
          </div>
          {/* <button onClick={this.setLowerCase.bind(this)}>Selection 1</button>
          <button onClick={this.setUpperCase.bind(this)}>Selection 2</button> */}
        </div>
      </NativeListener>
    );
  }
}

// sort values [x]
// format currency , round cents (2) [X]
// reset search (X)
// add categories (X)

// new sheet for not income/expence [x]
// col1 name col2 value sorted by value [x]
// all blank fields will be considered as not inc/exp [x]

// backend integration


// Hide list of expense for value >0
// ===> either select not income || expense or add it manually
// Change company name from sheet above

// ZELLE PAYMENT TO NOOR CAFE#Debit =====> -340
// ZELLE PAYMENT TO NOOR CAFE#Debit =====> -340
// ZELLE PAYMENT TO NOOR CAFE#Debit =====> -340
// ZELLE PAYMENT TO NOOR CAFE#Debit =====> -340

//

