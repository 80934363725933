/** @jsxImportSource @emotion/react */

import { useState } from 'react';
import { Link,useLocation } from 'react-router-dom';

import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Title from '../../components/Title/Title';
import FormImage from '../../components/FormImage/FormImage';

import logo from '../../assets/images/logo.svg';

import {
  footerLogoStyles,
  footerStyles,
  forgotLinkStyles,
  forgotStyles,
  headerStyles,
  imagesContainerStyles,
  inputStyles,
  logoStyles,
} from './styles';
import {connect} from "react-redux";
import {setError} from "../../store/error";
import {setLoggedInSuccess} from "../../store/user";
import {Notification} from "rsuite";

const axios = require('axios').default;

const LoginPage = ({setError,setLoggedInSuccess}) => {
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const { search } = useLocation()
  function handleSubmit(event) {
    event.preventDefault();
  if(!userEmail || !userPassword)
    setError("please enter your email and password")
    else{
      axios.post('/auth/login',{username:userEmail,email:userEmail,password:userPassword}).then( res=>{
            setLoggedInSuccess(res.data)
          }).catch((err)=>{
            Notification['error']({
              title: 'error',
              description: "Wrong Credentials"
            });
          })
    }
  }
  return (
    <section className="py-5">
      <div className="container">
        <div className="row">
          {/* image */}
          <div css={imagesContainerStyles} className="col-10 col-md-6 mx-auto">
            <FormImage title="Don't have an Account?" isLink />
          </div>
          {/* form */}
          <div className="col-10 col-md-6 mx-auto">
            <div css={headerStyles}>
              <img css={logoStyles} src={logo} alt="logo" />
              <Link to={{
                pathname:"/",
                 search
              }}>Go to home</Link>
            </div>

            <Title
              title1="Hi buddy,"
              title2="welcome"
              title3="Back!"
              text="Still don't have an account?"
              pathText="Sign up"
              path="/register"
            />
            <form onSubmit={handleSubmit} >
              <Input
                fullWidth="100%"
                label="Email"
                labelId="Email"
                type="email"
                placeholder="Email@example.com"
                value={userEmail}
                handleChange={(e) => setUserEmail(e.target.value.toLowerCase())}
                name={'username'}
                required
              />

              <Input
                fullWidth="100%"
                label="Password"
                labelId="Password"
                type="password"
                placeholder="Enter Password"
                value={userPassword}
                handleChange={(e) => setUserPassword(e.target.value)}
                name={'password'}
                required
              />

              <div css={forgotStyles}>
                <Input
                  type="checkbox"
                  labelId="checkbox"
                  label="Keep me logged in"
                  styles={inputStyles}
                  color="black"
                />
                <Link to={{pathname:"/login",search
              }} css={forgotLinkStyles}>
                  Forgot Password
                </Link>
              </div>

              <Button title="login"  type="submit"/>
            </form>

            <div css={footerStyles}>
              <span>&copy; Copyright {new Date().getFullYear()}</span>
              <img css={footerLogoStyles} src={logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({}) => {
  return {
  };
};
export default connect(mapStateToProps, {
  setError,setLoggedInSuccess
})(LoginPage);
