import InputSearch from '../../components/InputSearch/InputSearch';
import {connect} from 'react-redux';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import TreeTable from '../../components/TreeTable/TreeTable';
import {useEffect, useState} from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { setFilesResult } from '../../store/files';
import HomePage from "../HomePage/HomePage";
import DashboardRoutes from "./DashboardRouter";

const DashboardPage = ({setFilesResult,files}) => {

  return (
    <>
      <Navbar />
      <section className="py-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-10 col-md-2">
              <Sidebar />
            </div>
            <div className="col-10 col-md-10 " style={{flex:1,display:'flex', flexDirection:'column'}} >
              <DashboardRoutes/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = ({file}) => {
  return {
    files:file.files
  };
};
export default connect(mapStateToProps, {
  setFilesResult
})(DashboardPage);
