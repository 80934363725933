import React, {useEffect} from "react";
import {connect} from "react-redux";
import {signOut} from "../../store/user";
import axios from "axios";
import {SetMessage} from "../../utils/error";
import {useHistory} from "react-router-dom";

const SignOut = ({signOut})=>{
    const history = useHistory();

    useEffect(()=>{
        axios.get('/auth/logout').then(()=>{
            SetMessage("Logged out Successfuly",'success')
            signOut()
            history.push('/login')
        }).catch()
    },[])
    return null

}
const mapStateToProps = ({auth}) => {
    return {
        user:auth.loggedInUser
    };
};
export default connect(mapStateToProps, {signOut
})(SignOut);