import { parseCurrency, formatToCurrency } from "./utils";

const dolalize = (value) => {
  if (typeof value === "string") {
    value = parseCurrency(value);
  }

  const formatted =
    value >= 0
      ? `$ ${formatToCurrency(value)}`
      : `$ (${formatToCurrency(Math.abs(value))})`;
  return formatted;
};

const getSum = (obj) => {
  let sum = 0;
  Object.keys(obj).forEach((o) => (sum += obj[o].sum));
  return sum;
};

const sortCurrency = (values, sortBy = "sum", incomes) => {
  const sortedvalues = values.sort((a, b) => {
    if (parseCurrency(a[sortBy]) < parseCurrency(b[sortBy])) {
      return incomes ? 1 : -1;
    }
    if (parseCurrency(a[sortBy]) > parseCurrency(b[sortBy])) {
      return incomes ? -1 : 1;
    }
    return 0;
  });
  return sortedvalues;
};

const sortValues = (values, incomes) => {
  const sortedvalues = values.sort((a, b) => {
    if (a.sum < b.sum) {
      return incomes ? 1 : -1;
    }
    if (a.sum > b.sum) {
      return incomes ? -1 : 1;
    }
    return 0;
  });
  return sortedvalues;
};

const classify = (obj, incomes) => {
  const classified = Object.keys(obj)
    .filter((r) => (incomes ? obj[r] >= 0 : obj[r] < 0))
    .map((category) => {
      return { category, sum: obj[category] };
    });
  sortValues(classified, incomes);
  return classified;
};

const categorize = (json, categories) => {
  //summ all categories
  const rawan = {};
  categories.forEach((c) => {
    let sum = 0;
    json
      .filter((j) => j[2] === c)
      .forEach((v) => {
        sum = Number(sum) + parseCurrency(v[1]);
      });
    if (sum !== 0) {
      rawan[c] = sum;
    }
  });
  return rawan;
};
// 1000/5 ==> 200 . 250
const getMax = (data) => {
  if (data.length > 1) {
    const max = data.reduce((a, b) => {
      return Math.max(Math.abs(a.sum) || a, Math.abs(b.sum));
    });
    return max;
  } else if (data.length === 1) {
    return data[0].sum;
  } else {
    return 0;
  }
  // data.sum
};

const selectColor = (value, max, colors) => {
  const colorLevel = max / (colors.length - 1);

  const colorIndex = Math.abs(Math.floor(Math.abs(value) / (colorLevel + 1)));

  if (colorIndex > colors.length - 1) {
    return colors[0]; // temp fix color issue
  } else {
    return colors[colorIndex];
  }
};

const StyleNotIncExp = (data) => {
  return sortCurrency(data, "1", 1).map((cell, index) => {
    return {
      sum: {
        v: dolalize(cell[1]),
        t: "s",
        s: {
          fill: {
            fgColor: { rgb: index % 2 === 0 ? "cccccc" : "ffffff" },
          },
          font: {
            bold: true,
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        },
      },
      category: {
        v: cell[0],
        t: "s",
        s: {
          font: { sz: 10 },
          alignment: { wrapText: true },
          fill: {
            fgColor: { rgb: index % 2 === 0 ? "cccccc" : "ffffff" },
          },
        },
      },
    };
  });
};

const colorize = (data, colors) => {
  let max = getMax(data);

  return data.map((cell, index) => ({
    empty: "",
    sum: {
      v: dolalize(cell.sum),
      t: "s",
      s: {
        // fill: {
        //   fgColor: { rgb: selectColor(cell.sum, max, colors) },
        // },
        font: {
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      },
    },
    empty: "",
    category: {
      v: cell.category,
      t: "s",
      s: {
        font: { sz: 10 },
        alignment: { wrapText: true },
      },
    },
  }));
};
export { categorize, dolalize, colorize, getSum, classify, StyleNotIncExp };
