/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { Link,useLocation} from 'react-router-dom';

import {connect} from "react-redux";
import {setError} from "../../store/error";
import {setLoggedInSuccess} from "../../store/user";
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Title from '../../components/Title/Title';
import FormImage from '../../components/FormImage/FormImage';
import logo from '../../assets/images/logoOLD.png';
import queryString from 'query-string'

import {
  footerLogoStyles,
  footerStyles,
  forgotStyles,
  headerStyles,
  imagesContainerStyles,
  inputStyles,
  logoStyles,
} from './styles';
 import { SetMessage } from '../../utils/error';
import axios from 'axios';
const RegisterPage = ({setLoggedInSuccess}) => {
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agree, setAgree] = useState(false);
  const { search } = useLocation()
useEffect(()=>{
},[])
const handleSubmit = (event)=>{
  const values = queryString.parse(search)

  event.preventDefault();
  if(!userEmail || !userPassword || !userName || !confirmPassword)
    SetMessage("please enter all fields",'error')
  else if(userPassword !== confirmPassword)
    SetMessage("password doesn't match confirm password",'error')
  else {
    axios.post('auth/register',{
      name:userName,
      password:userPassword,
      username:userEmail,
      email:userEmail,
      utmSource:values.utm_source,
      utmCampaign:values.utm_campaign,
      utmMedium:values.utm_medium
    }).then((res)=>{
      setLoggedInSuccess(res.data)
      SetMessage("signing in is successful","success")
    }).catch(err=>{
      SetMessage("Error",'error')
    })
  }
}
  return (
    <section className="py-5">
      <div className="container">
        <div className="row">
          {/* image */}
          <div css={imagesContainerStyles} className="col-10 col-md-6 mx-auto">
            <FormImage title="We have a “strategic” plan its called doing things." />
          </div>
          {/* form */}
          <div className="col-10 col-md-6 mx-auto">
            <div css={headerStyles}>
              {/* <img css={logoStyles} src={logo} alt="logo" /> */}
              <Link to={{pathname:"/",search
              }}>Go to home</Link>
            </div>
            <form onSubmit={handleSubmit}> 
              <Input
                fullWidth="100%"
                label="Full Name"
                labelId="username"
                type="text"
                placeholder="Melvin Carlson"
                value={userName}
                handleChange={(e) => setUserName(e.target.value)}
              />

              <Input
                fullWidth="100%"
                label="Email"
                labelId="Email"
                type="email"
                placeholder="Email@example.com"
                value={userEmail}
                handleChange={(e) => setUserEmail(e.target.value.toLowerCase())}
              />

              <Input
                fullWidth="100%"
                label="Password"
                labelId="Password"
                type="password"
                placeholder="Enter Password"
                value={userPassword}
                handleChange={(e) => setUserPassword(e.target.value)}
              />

              <Input
                fullWidth="100%"
                label="Re-type Password"
                labelId="Password1"
                type="password"
                placeholder="Enter Password"
                value={confirmPassword}
                handleChange={(e) => setConfirmPassword(e.target.value)}
              />

              <div css={forgotStyles}>
                <div>
                 By clicking "SIGN UP" You agree to the Terms and Conditions and Privacy Policy.
               </div>
              </div>

              <Button title="sign up" />
            </form>

            <div css={footerStyles}>
              <span>&copy; Copyright {new Date().getFullYear()}</span>
              <img css={footerLogoStyles} src={logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const mapStateToProps = ({}) => {
  return {
  };
};
export default connect(mapStateToProps, {
  setError,setLoggedInSuccess
})(RegisterPage);
