import {
  categorize,
  dolalize,
  colorize,
  getSum,
  classify,
  StyleNotIncExp,
} from "./helper";
import * as xlsx from "xlsx-js-style";
import { getCategory } from "./data";

const Incomecolors = ["ACDF87", "66B857", "4C9A2A", "1E5631"];
const Expensescolors = ["a70000", "ff0000", "ff5252", "ff7b7b", "ffbaba"];

const exportData = async (data, name) => {
  const organizationName = name?.split(".")[0];

  const emptyFieleds = [];
  const notEmpty = [];
  const filterEmpy = () => {
    const d = data.forEach((row) => {
      if (row[0] && row[1]) {
        if (row[2] !== "") {
          notEmpty.push(row);
        } else {
          row[1] = row[1].slice(1);
          emptyFieleds.push(row);
        }
      }
    });
  };
  filterEmpy();

  const categorized = categorize(notEmpty, getCategory());

  const incomes = classify(categorized, 1);
  const expenses = classify(categorized, 0);
  const netIncomes = getSum(incomes);
  const netExpenses = getSum(expenses);
  const netTotal = (netIncomes + netExpenses).toFixed(2);
  const ws = xlsx.utils.aoa_to_sheet([
    [""],
    [
      {
        v: organizationName,
        t: "s",
        s: {
          font: {
            bold: true,
            sz: 12,
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        },
      },
    ],
    [
      {
        v: "Income and Expenses",
        t: "s",
        s: {
          font: {
            bold: true,
            sz: 12,
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        },
      },
    ],
    [
      {
        v: "For the period beginning .... ,and ending .... ",
        t: "s",
        s: {
          font: {
            bold: true,
            underline: true,
            sz: 12,
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        },
      },
    ],
  ]);

  xlsx.utils.sheet_add_aoa(
    ws,
    [
      [""],
      [
        {
          v: `Revenue :`,
          t: "s",
          s: {
            border: {
              bottom: { style: "thin", color: { rgb: "333333" } },
            },
            alignment: {
              vertical: "center",
            },
          },
        },
      ],
    ],
    { origin: -1 }
  );

  let colorizedIncomes = colorize(incomes, Incomecolors);
  let colorizedExpences = colorize(expenses, Expensescolors);

  xlsx.utils.sheet_add_json(ws, colorizedIncomes, {
    header: ["category", "sum"],
    skipHeader: true,
    origin: "B7",
  });

  xlsx.utils.sheet_add_json(
    ws,
    [
      {
        empty: "",
        category: {
          v: "Gross Profit",
          t: "s",
          s: {
            fill: {
              fgColor: { rgb: "c6d9f0" },
            },
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
          },
        },
        empty: "",
        sum: {
          v: dolalize(netIncomes),
          t: "s",
          s: {
            border: {
              bottom: { style: "thin", color: { rgb: "333333" } },
              top: { style: "thin", color: { rgb: "333333" } },
            },
            fill: {
              fgColor: { rgb: "c6d9f0" },
            },
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
          },
        },
      },
    ],
    {
      header: ["empty", "category", "empty", "sum"],
      skipHeader: true,
      origin: -1,
    }
  );

  // Gross Profit

  xlsx.utils.sheet_add_aoa(
    ws,
    [
      [""],
      [
        {
          v: `Expenses :`,
          t: "s",
          s: {
            border: { bottom: { style: "thin", color: { rgb: "333333" } } },
            alignment: {
              vertical: "center",
            },
          },
        },
      ],
      [""],
    ],
    { origin: -1 }
  );

  xlsx.utils.sheet_add_json(ws, colorizedExpences, {
    header: ["car", "category", "sum"],
    skipHeader: true,
    origin: -1,
  });

  xlsx.utils.sheet_add_json(
    ws,
    [
      {
        empty: "",
        category: {
          v: "Total Expenses",
          t: "s",
          s: {
            fill: {
              fgColor: { rgb: "eaf1dd" },
            },
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
          },
        },
        empty: "",
        sum: {
          v: dolalize(netExpenses),
          t: "s",
          s: {
            border: {
              bottom: { style: "thin", color: { rgb: "333333" } },
              top: { style: "thin", color: { rgb: "333333" } },
            },
            fill: {
              fgColor: { rgb: "eaf1dd" },
            },
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
          },
        },
      },
    ],
    {
      header: ["empty", "category", "empty", "sum"],
      skipHeader: true,
      origin: -1,
    }
  );

  xlsx.utils.sheet_add_json(
    ws,
    [
      {
        empty: "",
        category: {
          v: "Net Income/(Loss)",
          t: "s",
          s: {
            border: {
              bottom: { style: "thin", color: { rgb: "333333" } },
            },
            fill: {
              fgColor: { rgb: "fbd4b4" },
            },
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
            },
          },
        },
        empty: "",
        sum: {
          v: dolalize(netTotal),
          t: "s",
          s: {
            border: {
              bottom: { style: "thick", color: { rgb: "333333" } },
            },
            fill: {
              fgColor: { rgb: "fbd4b4" },
            },
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
          },
        },
      },
    ],
    {
      header: ["category", "empty", "empty", "sum"],
      skipHeader: true,
      origin: -1,
    }
  );

  const workbook = xlsx.utils.book_new();

  const notIE = xlsx.utils.aoa_to_sheet([
    [""],
    [
      {
        v: "Not selected category",
        t: "s",
        s: {
          font: {
            bold: true,
            sz: 12,
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        },
      },
    ],
  ]);

  const merge = [
    { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } },
    { s: { r: 2, c: 0 }, e: { r: 2, c: 4 } },
    { s: { r: 3, c: 0 }, e: { r: 3, c: 4 } },
    {
      s: { r: incomes.length + 6, c: 1 },
      e: { r: incomes.length + 6, c: 2 },
    },
    {
      s: { r: expenses.length + incomes.length + 10, c: 1 },
      e: { r: expenses.length + incomes.length + 10, c: 2 },
    },
  ];

  const getWidth = (arr) => {
    const widthColumn = Math.max(
      ...arr.map((a2) =>
        a2.sum ? a2.sum.toString().length : a2.toString().length
      )
    );
    return widthColumn + 4;
  };

  const getWidthIE = (arr) => {
    const widthColumn = Math.max(
      ...arr.map((a2) => (a2[1] ? a2[1].toString().length : 0))
    );
    return widthColumn + 5;
  };

  const fitToColumn = (arrayOfArray) => {
    return [
      { wch: 18 },
      { wch: 30 },
      { wch: getWidth(arrayOfArray) },
      { wch: getWidth([netIncomes, netExpenses, netTotal]) },

      { hidden: true },
    ];
  };

  const fitNotIE = () => {
    return [{ wch: 30 }, { wch: getWidthIE(emptyFieleds) }, , { hidden: true }];
  };

  ws["!cols"] = fitToColumn([...incomes, ...expenses]);

  ws["!merges"] = merge;
  // ws["!cols"] = wscols;

  notIE["!merges"] = [{ s: { r: 1, c: 0 }, e: { r: 1, c: 2 } }];
  notIE["!cols"] = fitNotIE();

  const generateNotIncExpSheet = () => {
    xlsx.utils.sheet_add_json(notIE, StyleNotIncExp(emptyFieleds), {
      header: ["category", "sum"],
      skipHeader: true,
      origin: "A4",
    });
  };
  generateNotIncExpSheet();
  xlsx.utils.book_append_sheet(workbook, ws, "Income and Expense");
  xlsx.utils.book_append_sheet(workbook, notIE, "Not selected category");

  xlsx.writeFile(workbook, "Income staement.xlsx", { compression: true });
};

export { exportData };
