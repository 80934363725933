let category = [
  "Accounting",
  "Advertising",
  "Automobile and truck expense",
  "Bad debts",
  "Bank charges",
  "Bond repurchase premium",
  "Cash short/over",
  "Cell phone",
  "Clean fuel vehicle deductions.",
  "Commissions",
  "Computer",
  "Consulting",
  "Credit and collection costs.",
  "Delivery",
  "Depreciation",
  "Depletion",
  "Discounts",
  "Dues and subscriptions",
  "Education and training",
  "Employee benefit programs",
  "Entertainment",
  "Equipment rental/lease",
  "Freight.",
  "Fuel",
  "Internet",
  "Laundry and cleaning",
  "Legal and professional",
  "Marketing",
  "Meals",
  "Meetings",
  "Miscellaneous",
  "Officer compensation",
  "Office expense",
  "Outside services and contractors",
  "Parking fees and tolls",
  "Payroll processing pipenses",
  "Pension, profit sharing, and other plans",
  "Permits and fees",
  "Postage/shipping",
  "Printing",
  "Recruiting",
  "Rents",
  "Repairs and maintenance",
  "Salaries and wages",
  "Sales",
  "Security",
  "Software",
  "Supplies",
  "Taxes and licenses",
  "Telephone",
  "Tools.",
  "Travel",
  "Uniforms",
  "Utilities",
  "Waste removal",
  "Insurance",
];

const setCategory = (newCategory) => {
  category = newCategory;
};
const getCategory = () => {
  return category;
};

const getSortedCategories = () => {
  category.sort((a, b) => {
    if (a.firstname < b.firstname) {
      return -1;
    }
    if (a.firstname > b.firstname) {
      return 1;
    }
    return 0;
  });
  return category
};

const stambData = [
  ["ONLINE TRANSFER#Credit", 226060.02, ""],
  ["ATM CASH DEPOSIT TH AVE ASTORIA NY#Credit", 23097, ""],
  ["ATM CASH DEPOSIT BROADWAY ASTORIA NY#Credit", 5053, ""],
  ["ATM CASH DEPOSIT LEXINGTON AVE#Credit", 2594, ""],
  ["ZELLE PAYMENT FROM JOSE A RAMIREZ#Credit", 1400, ""],
  ["ATM CASH DEPOSIT RD AVE NEW#Credit", 1202, ""],
  ["ZELLE PAYMENT FROM MARIA MORAN#Credit", 954, ""],
  ["ONLINE TRANSFER FROM CHK TRANSACTION#Credit", 600, ""],
  ["ZELLE PAYMENT FROM ALAM M RAFIQI#Credit", 545, ""],
  ["ZELLE PAYMENT FROM JOSHUA GATEWOOD BACON UZMUI#Credit", 510, ""],
  ["ZELLE PAYMENT FROM RAFAEL ARESJR SVB H AMT XD#Credit", 290, ""],
  [
    "ZELLE PAYMENT FROM GUNES PRODUCE CORP GUNES PRODUCE CO BACVWBNRFCNW#Credit",
    250,
    "",
  ],
  ["ZELLE PAYMENT FROM OMAR KHALIL CTIQPQR YOXN#Credit", 230, ""],
  ["MISCELLANEOUS REVENUES", 1147.4, ""],
  ["KEY FOODS NEWTOWN ASTORIA NY#Debit", -201.44, ""],
  ["TELECHARGE SVCE TELECHARGE NY#Debit", -207.5, ""],
  ["TRUE RELIGION CENTRAL VALLE NY#Debit", -212.06, ""],
  ["VISTA ALEGRE CHIADO LISBOA EURO X EXCHG RTE#Debit", -223.91, ""],
  ["SQ WHITE FLOWER FARMHO SOUTHOLD NY#Debit", -240.06, ""],
  ["PARIS EM LISBOA LISBOA EURO X EXCHG RTE#Debit", -244.77, ""],
  ["SAMMY BROKERAGE INC ASTORIA NY#Debit", -309, ""],
  ["ZELLE PAYMENT TO AHMED SAAD JPM ECBDW#Debit", -311, ""],
  ["IN DIVISION SEVEN TEA MA#Debit", -311.6, ""],
  ["ZELLE PAYMENT TO PANIKOS GEORGIOU JPM ED DXE#Debit", -330, ""],
  ["ASTOUND POWERED BY RC PA#Debit", -330.25, ""],
  ["ZELLE PAYMENT TO NOOR CAFE#Debit", -340, ""],
  ["ZELLE PAYMENT TO DANY#Debit", -400, ""],
  ["STAPLES ASTORIA NY#Debit", -402.67, ""],
  ["EXTRA SPACE QUEENS NY#Debit", -420, ""],
  [
    "UTICA FIRST INSU   INSURANCE SEC CCD TRACE EED IND ID IND NAME ALADDIN AND PRINCESS Z#Debit",
    -445.19,
    "",
  ],
  ["MEM TEA IMPORTS MA#Debit", -477.4, ""],
  ["MARNI WOODBURY WOODBURY NY#Debit", -481.16, ""],
  ["ZELLE PAYMENT TO ANGELA#Debit", -500, ""],
  ["ZELLE PAYMENT TO JOE WORK JPM C GZUG#Debit", -545, ""],
  ["ZELLE PAYMENT TO JOE WORK JPM D ODBY#Debit", -545, ""],
  ["ANTHROPOLOGIE NOR NEW YORK NY#Debit", -577.46, ""],
  ["KURUCESME MANDARIN#Debit", -581.24, ""],
  ["IKEA BROOKLYN BROOKLYN NY#Debit", -607.8499999999999, ""],
  ["GUERLAIN BOUTIQUE AT TH NEW YORK NY#Debit", -630.77, ""],
  ["NYC ECB FINES NY#Debit", -665.54, ""],
  ["NYC OATH NEW#Debit", -693.6, ""],
  ["INSUFFICIENT FUNDS FEE#Debit", -850, ""],
  ["AIR FRANCE#Debit", -866.47, ""],
  ["CRT#Debit", -909.6500000000001, ""],
  ["NYC DEPT OF FINA#Debit", -1280, ""],
  ["IND ID#Debit", -1398, ""],
  [
    "NYS DTF CT ORIG ID G DESC  TAX PAYMNTSEC CCD TRACE EED IND ID IND NAME CL TRN TC#Debit",
    -1655,
    "",
  ],
  ["BERKSHIRE HATHAWAY IN PA#Debit", -1674, ""],
  ["COSTCO WHSE LONG ISLAND C NY#Debit", -1771.52, ""],
  ["NON CHASE ATM WITHDRAW#Debit", -1902.5, ""],
  ["NYC DOHMH LICENSE PE#Debit", -2318.46, ""],
  ["THE WEBSTAURANT STORE PA#Debit", -2367.63, ""],
  ["ATM WITHDRAWAL BROADW#Debit", -2400, ""],
  ["ATM WITHDRAWAL BROA#Debit", -2800, ""],
  ["ZELLE PAYMENT TO GREGORIO#Debit", -2900, ""],
  ["FEATHERSTONE FOODS NY#Debit", -3000, ""],
  ["IRS#Debit", -3159, ""],
  ["RESTAURANT DEPOT MASPETH NY#Debit", -3522.06, ""],
  ["LAVAZZA PREMIUM#Debit", -8919.26, ""],
  ["IND NAME#Debit", -11301.51, ""],
  ["ATM WITHDRAWAL#Debit", -11770, ""],
  ["WITHDRAWAL#Debit", -23000, ""],
  ["CHASE#Debit", -25096.71, ""],
  ["NYS#Debit", -27801.98, ""],
  ["ONLINE TRANSFER#Debit", -42780, ""],
  ["CHECK#Debit", -60409.33, ""],
  ["MISCELLANEOUS EXPENSES", -5651.250000000001, ""],
];

export { category, getCategory, setCategory, stambData, getSortedCategories };
