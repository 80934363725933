/** @jsxImportSource @emotion/react */

import { IoIosNotificationsOutline } from "react-icons/io";
import { AiOutlineMessage } from "react-icons/ai";
import { RiArrowDownSLine } from "react-icons/ri";
import { connect } from "react-redux";

import logo from "../../assets/images/logoOLD.png";
import avatarImg from "../../assets/images/avatar.png";

import {
  avatarContainerStyles,
  avatarStyles,
  headerStyles,
  infoStyles,
  logoStyles,
  messageIconStyles,
  navStyles,
  noteficationIconStyles,
  noteficationStyles,
  numberStyles,
} from "./styles";
import { Dropdown, Icon } from "rsuite";
import { useEffect } from "react";
import axios from "axios";
import { SetMessage } from "../../utils/error";
import { signOut } from "../../store/user";
import { Link, NavLink } from "react-router-dom";

const Navbar = ({ user, signOut }) => {
  const logout = () => {
    axios
      .get("/auth/logout")
      .then(() => {
        SetMessage("Logged out Successfuly", "success");
        signOut();
      })
      .catch();
  };
  return (
    <header css={headerStyles}>
      <div className="container-fluid">
        <nav css={navStyles}>
          <NavLink to="/dashboard" >
          <img css={logoStyles} src={logo} alt="logo" className="mx-2" />

          </NavLink>
          <div css={infoStyles}>
            {/* <AiOutlineMessage css={messageIconStyles} size={17} />
            <span css={noteficationStyles}>
              <IoIosNotificationsOutline
                size={20}
                css={noteficationIconStyles}
              />
              <span css={numberStyles}>22</span>
            </span> */}
            <div css={avatarContainerStyles} className="mx-2">
              <a
                style={{
                  color: "#2d2d2d",
                  "text-decoration": "none",
                  fontWeight: "bold",
                }}
              >
                Credits {user.credit}
              </a>

              <Dropdown title={user.name} icon={<Icon icon="user" size="lg" />}>
                <Dropdown.Item
                  onSelect={logout}
                  icon={<Icon icon="sign-out" />}
                >
                  Sign out{" "}
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    user: auth.loggedInUser,
  };
};
export default connect(mapStateToProps, { signOut })(Navbar);
