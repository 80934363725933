import {createSlice} from "@reduxjs/toolkit";

const error = createSlice({
    name:'error',
    initialState:{
        error: false,
        errorMessage:false
    },
    reducers: {
        setError(state, action){
            state.error = action.payload
            state.errorMessage = action.payload
        },
        clearError(state,action){
            state.errorMessage = false
            state.error=false
        }
    }
})
const {actions,reducer}= error

export const {setError,clearError} =actions
export default reducer;