import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { setFilesResult } from "../../store/files";
import TreeTable from "../../components/TreeTable/TreeTable";
import InputSearch from "../../components/InputSearch/InputSearch";
import { setCredit } from "../../store/user";
import { Link } from "react-router-dom";
const axios = require("axios").default;

const HomePage = ({ files, setFilesResult, setCredit }) => {
  const [tableFiles, setTableFiles] = useState([]);

  useEffect(() => {
    setTableFiles(_.cloneDeep(files));
  }, [files]);

  const getFiles = () => {
    axios.get("/files").then((res) => {
      setFilesResult(res.data.files);
      setCredit(res.data.credit);
    });
  };
  useEffect(() => {
    getFiles();
    let interval = setInterval(() => {
      getFiles();
    }, 20000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div>
      <InputSearch />

      <TreeTable data={tableFiles} getFiles={getFiles} />
    </div>
  );
};
const mapStateToProps = ({ file }) => {
  return {
    files: file.files,
  };
};
export default connect(mapStateToProps, {
  setFilesResult,
  setCredit,
})(HomePage);
