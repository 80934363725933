/** @jsxImportSource @emotion/react */

import { Link, NavLink } from 'react-router-dom';
import { AiOutlineHome, AiOutlineSetting } from 'react-icons/ai';
import {BiMoney,BiCreditCard,BiExit} from "react-icons/bi";
import { BiBarChartAlt2, BiEnvelope } from 'react-icons/bi';
import { FiHelpCircle } from 'react-icons/fi';

import { iconStyles, linkStyles, listStyles, textStyles } from './styles';

const Sidebar = () => {
  return (
    <ul css={listStyles}>
      <li>
        <NavLink to="/dashboard" css={linkStyles}>
          <AiOutlineHome css={iconStyles} />
          <span css={textStyles}>Home</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/funds" css={linkStyles}>
          <BiMoney css={iconStyles} />
          <span css={textStyles}>Add Funds</span>
        </NavLink>
      </li>

      {/*<li>*/}
      {/*  <NavLink to="/payment_methods" css={linkStyles}>*/}
      {/*    <BiCreditCard css={iconStyles} />*/}
      {/*    <span css={textStyles}>Payment Methods</span>*/}
      {/*  </NavLink>*/}
      {/*</li>*/}

      {/*<li>*/}
      {/*  <NavLink to="/" css={linkStyles}>*/}
      {/*    <FiHelpCircle css={iconStyles} />*/}
      {/*    <span css={textStyles}>Help Center</span>*/}
      {/*  </NavLink>*/}
      {/*</li>*/}

      <li>
        <NavLink to="/logout" css={linkStyles} >
          <BiExit css={iconStyles} />
          <span css={textStyles}>Log Out</span>
        </NavLink>
      </li>
    </ul>
  );
};

export default Sidebar;
