/** @jsxImportSource @emotion/react */

import { btnStyles } from './styles';
import {Icon} from "rsuite";

const Button = ({ title, type, onClick, loading=false }) => {
  return (
      !loading ?
    <button css={btnStyles} type={`${'submit' || type}`} onClick={onClick}>
       {title}
    </button> :
          <div css={btnStyles} style={{textAlign:'center'}} >
            <Icon icon={'spinner'} spin/>
          </div>
  );
};

export default Button;
