import ReactDOM from "react-dom";
import { HotTable, HotColumn } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import Options from "./Options";

// register Handsontable's modules
registerAllModules();

const Sheet = ({ data }) => {
  return (
    <HotTable
      data={data}
      startRows={5}
      startCols={data.length}
      height="auto"
      width="100%"
      colHeaders={true}
      minSpareRows={1}
      licenseKey="non-commercial-and-evaluation"
      minSpareCols={1}
      stretchH="all"
    >
      <HotColumn width={300}></HotColumn>
      <HotColumn width={100}></HotColumn>
      <HotColumn width={250}>
        <Options hot-editor />
      </HotColumn>
    </HotTable>
  );
};

export default Sheet;
